import React, { Component } from 'react'
import { GetServiceStyle } from './GetService.styles'
import ContactInfoCard from 'components/ContactInfoCard/ContactInfoCard'

class GetService extends Component {
  render() {
    const { getServiceProps } = this.props.getServiceData
    const CTACards = getServiceProps.CTACard

    return (
      <GetServiceStyle>
        <div className="container">
          <div className="heading-wrapper">
            <h3>{getServiceProps.heading}</h3>
          </div>
          <div className="getservice-list">
            {getServiceProps.getserviceLists.map((getserviceList, i) => {
              return (
                <div
                  key={i}
                  className={`getservice-single ${getserviceList.className}`}
                >
                  <div className="icon">
                    <img src={getserviceList.icon} alt="" />
                  </div>
                  <h4>{getserviceList.title}</h4>
                  <p>{getserviceList.content}</p>
                </div>
              )
            })}
          </div>
        </div>
        <ContactInfoCard {...CTACards} />
      </GetServiceStyle>
    )
  }
}

export default GetService
