import React from 'react'
import { HowItWorksFlowStyle } from './HowItWorksFlow.styles'
import { Accordion, AccordionItem } from 'components/Accordion'
import Image from 'components/ImgOptimized'

const HowItWorksFlow = props => {
  const { howitworkflowProps } = props.howitworkflowData
  const {
    data: {
      workflow1: {
        childImageSharp: { fluid: workflowImg1 },
      },
      workflow2: {
        childImageSharp: { fluid: workflowImg2 },
      },
      workflow3: {
        childImageSharp: { fluid: workflowImg3 },
      },
      workflow4: {
        childImageSharp: { fluid: workflowImg4 },
      },
    },
  } = props
  let items = [workflowImg1, workflowImg2, workflowImg3, workflowImg4]

  return (
    <HowItWorksFlowStyle>
      <div className="container">
        <div className="howitworkflow-wrapper">
          <div className="flow-content">
            {howitworkflowProps.workflowrows.map((workflowrow, i) => {
              return (
                <div key={i} className="content-row">
                  <div className="img-blk">
                    <Image
                      fluid={items[i]}
                      alt={workflowrow.altText}
                      lazyload={{
                        enabled: true,
                        once: true,
                        offset: 1000,
                        heigth: 330,
                      }}
                    />
                  </div>
                  <div className="content-blk">
                    <h3>{workflowrow.heading}</h3>
                    <p>{workflowrow.mainpara}</p>
                    <div className="faq-accoridon-wrapper">
                      <Accordion atomic>
                        {workflowrow.faqProps.map((faqProps, index) => {
                          return (
                            <AccordionItem
                              key={index}
                              title={faqProps.faqTitle}
                              isOpen={index === 0}
                            >
                              <p>{faqProps.faqPara}</p>
                            </AccordionItem>
                          )
                        })}
                      </Accordion>
                    </div>
                    <div className="divider">
                      <img src={workflowrow.dividerImg} alt="" />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </HowItWorksFlowStyle>
  )
}

export default HowItWorksFlow
