import styled from 'styled-components'
import { bodyFont, lg, sm, md } from 'config/variables'

export const GetServiceStyle = styled.section`
  margin: 0;
  padding: 140px 0 100px;
  background: linear-gradient(to top, #eef2ff, #ffffff);
  ${lg(`
    padding: 90px 0 90px;
  `)}
  ${md(`
    padding: 60px 0 60px;
  `)}
  ${sm(`
    padding: 40px 0 40px;
  `)}
  .getservice-list {
    margin-top: 40px;
    border-left: 1px solid #e8e9f3;
    border-top: 1px solid #e8e9f3;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-wrap: wrap;
    ${lg(`
      border: 1px solid #e8e9f3;
    `)}
    ${sm(`
      border-right: 0;
      border-bottom: 0;
    `)}
    .getservice-single {
      flex: 33.33%;
      max-width: 33.33%;
      border-right: 1px solid #e8e9f3;
      border-bottom: 1px solid #e8e9f3;
      padding: 30px;
      ${lg(`
        flex: 50%;
        max-width: 50%;
      `)}
      ${md(`
        padding: 20px;
        `)}
      ${sm(`
        flex: 100%;
        max-width: 100%;
      `)}
      .icon {
        margin-bottom: 7px;
      }
      h4 {
        font-size: 22px;
        line-height: 28px;
        font-family: ${bodyFont};
        font-weight: 500;
        display: block;
        margin-bottom: 7px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
        margin: 0;
        strong {
          font-weight: 500;
        }
      }
      &.sage-green {
        h4 {
          color: #85c26a;
        }
      }
      &.sky-blue {
        h4 {
          color: #6cbaf7;
        }
      }
      &.bluegrey {
        h4 {
          color: #7cb1d3;
        }
      }
      &.pale-orange {
        h4 {
          color: #f6a25f;
        }
      }
      &.carnation {
        h4 {
          color: #ef7c91;
        }
      }
      &.perrywinkle {
        h4 {
          color: #8d7fd8;
        }
      }
      &.robin-s-egg {
        h4 {
          color: #63d5ff;
        }
      }
    }
  }
  .contact-info-card{
    margin: 0 auto;
    margin-top: 100px;
    ${lg(`
      margin-top: 70px;
    `)}
    ${md(`
      margin-top: 50px;
    `)}
  }
`
