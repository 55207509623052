import React from 'react'
import { images } from 'config/images'

export const HowItWorksProps = {
  bannerProps: {
    bannerHeading: (
      <>
        How building <br />
        <span className="heading-red">development team</span> works
      </>
    ),
    bannerHeadingSpan: 'works',
    bannerContent: (
      <>
        Extend your tech team with top talent. We oversee the execution and
        management of your software projects with our proven processes and tech
        mentorship
      </>
    ),
  },
  howitworkflowProps: {
    workflowrows: [
      {
        title: 'Roadmap',
        altText: 'Roadmap',
        heading: (
          <>
            Get guidance on the{' '}
            <span className="highlight-head">right technologies</span> and
            skills to invest in
          </>
        ),
        mainpara: (
          <>
            Let us know about your project needs and goals—the more detailed the
            better. Depending on your goals, we prepare a{' '}
            <span className="color-through">tech roadmap</span> with a hiring
            plan and technical consultation.
          </>
        ),
        faqProps: [
          {
            faqTitle:
              'What does your tech consultation include? Why is it valuable?',
            faqPara: (
              <>
                The detailed technical consultation (which itself is worth
                thousands of dollars in value) includes things like challenges
                of the project, what tech-stack to use to solve those
                challenges. A detailed hiring plan is also part of this
                consultation and includes details on what skill set and
                experience your team need to have.
                <br />
                <br />
                Project’s execution roadmap brings all the pieces together to
                show how your project will come to life. Based on your project
                goals we help you define processes and delivery roadmap that
                suits your needs.
                <br />
                <br />
                Tech architecture solution includes things like how features
                will be implemented with what technology and framework. It will
                also include things like algorithms and cloud integrations will
                be required to build your IP and build the tech engine.
                <br />
                <br />
                This tech consultation and talent skillset specification are
                provided for free so even if you don't work with us you can take
                it forward and use it in the future.
              </>
            ),
          },
          {
            faqTitle:
              'How is Simform different from other online platforms and development agencies?',
            faqPara: (
              <>
                Simform’s team extension service allows you to hire pre-vetted,
                world-class developers as part of your team.
                <br />
                <br />
                We handle all aspects of finding, vetting, and choosing the
                right candidates that you don't have the time, focus, desire, or
                sometimes expertise to do.
                <br />
                <br />
                Most of our competitors provide TRANSACTIONAL hiring services.
                You post a gig, they match you up with a developer and you take
                it from there. We aren't a transactional service.
                <br />
                <br />
                Best of all, you don’t need to spend hours sifting through
                online profiles, sorting candidates by hourly rates, and then
                picking the person you hope can do the job quickly, reliably,
                and for the right price.
                <br />
                <br />
                We aren't just adding development capacity to your team. Our
                value is equally distributed across the entire project execution
                lifecycle. We take ownership and guarantee the delivery and
                quality of your project.
                <br />
                <br />
                We use our proven processes, experience, and engagement models
                to make sure remote engagement delivers successful results.
                <br />
                <br />
                This way you get the best of both worlds. You get transparency,
                access, and skill set you get from working with full-time
                developers. You also get the benefit of an experienced
                technology firm who is involved in successfully delivering your
                project.
              </>
            ),
          },
          {
            faqTitle: 'What kind of commitment do I need to make up front?',
            faqPara:
              'None! We operate under the policy that it is our responsibility to find you the right person for the job. If you don’t find the right one, you won’t spend a dime.',
          },
        ],
        dividerImg: images.pdivider1,
      },
      {
        title: 'Hire',
        altText: 'Hire',
        heading: (
          <>
            Hire the <span className="highlight-head">best developers</span> for
            your project
          </>
        ),
        mainpara: (
          <>
            Whether you want to add a member to your existing tech team or build
            a standalone project from scratch, we can help you find the{' '}
            <a href="/hire/dedicated-developers/">best developers</a> for the
            job and make sure the project is successfully delivered on time.
          </>
        ),
        faqProps: [
          {
            faqTitle: 'What is your developer vetting process? ',
            faqPara: (
              <>
                Finding developers and freelancers is easy — the hard part is
                knowing if you have found people who will truly deliver. This is
                specifically difficult if you don't have years of experience in
                vetting and choosing the best developers from a range of
                technologies. We do.
                <br />
                <br />
                When we have candidates we like we give them a stage to showcase
                their communication skills and problem-solving ability through a
                series of video interviews. Here we are looking for people with
                highly relevant skill sets, well-documented code, and a
                disciplined approach to testing.
                <br />
                <br />
                We develop comprehensive small project tests for every role.
                Each developer candidate's technical acumen is tested through an
                automated coding exam. Applicants are tested on
                language-specific knowledge as well as general programming and
                algorithm knowledge.
                <br />
                <br />
                We do full reference checks with their previous clients and
                employers. We sign NDA and full proof legal contract to make
                sure your IP is protected. Speed is what Simform takes pride in
                — it typically takes only 24-72 hours for our clients to receive
                a short list of top candidates.
              </>
            ),
          },
          {
            faqTitle:
              'What are the benefits of working with remote developers?',
            faqPara: (
              <>
                Remote teams, whether comprised of developers from 50 miles away
                from you or 5,000 miles, bring pretty extraordinary advantages.
                <br />
                <br />
                Knowledge sharing & documentation
                <br />
                <br />
                The great thing about remote teams is that you are encouraged to
                keep communications in text format, which also means you will
                have a written record of all communication. In an office, so
                much is said casually in passing, and that knowledge is never
                captured.
                <br />
                <br />
                You become more disciplined and process driven when working in a
                remote environment. This is great for any team but sometimes
                missed in in house teams.
                <br />
                <br />
                Flexibility
                <br />
                There is no greater sign of trust and respect you can give
                someone on your team than by offering flexibility with their
                schedule. They will harness that flexibility to give you
                incredibly focused hours of work that will be far more
                productive than office-hours would ever be.
                <br />
                <br />
                Focus
                <br />
                Offices are great for brainstorming and collaboration, but they
                cannot give you the focus you need for execution. Working
                remotely can. Development work requires intense focus and
                uninterrupted productive time.
                <br />
                <br />
                Access to great talent around the world
                <br />
                <br />
                With the world as your hiring pool, you can recruit far more
                developers and far more talented developers than the leftovers
                available in your local area. You will also end up with a far
                more diverse team of people with many different perspectives and
                approaches to solving challenges.
                <br />
                <br />
                More opportunity to learn and grow
                <br />
                Without a commute and more time available to them, remote
                developers are some of the most skilled and up-to-date
                developers in the world, thanks to the flexibility they have to
                keep learning and growing.
                <br />
                <br />
                Timezones can be harnessed for greater productivity
                <br />
                Most people assume that remote development is chaotic because of
                the differing time zones. This is actually the least of most
                remote teams’ worries. Time Zones are often utilized wisely so
                that there is enough overlap for each of the team members to
                sync up, but also enough difference in time to allow for
                continuous development to happen as one developer hands off work
                to another developer.
              </>
            ),
          },
          {
            faqTitle: 'Do you have developers in my city?',
            faqPara:
              'Most of our developers work remotely and are experienced in working with distributed teams. If an on-site developer is strictly required, we can certainly work with you to make it happen. We can tap into our partner network and a vetted list of developers to help you find a local developer.',
          },
        ],
        dividerImg: images.pdivider2,
      },
      {
        title: 'Engagement',
        altText: 'Engagement',
        heading: (
          <>
            You won’t know where{' '}
            <span className="highlight-head">your team </span> ends and ours
            begins
          </>
        ),
        mainpara: (
          <>
            We match our development operations to your{' '}
            <span className="color-through">engineering culture</span>,
            processes and ways of working. Our developers work within your
            systems and our tech leads receive their priorities directly from
            you.
          </>
        ),
        faqProps: [
          {
            faqTitle:
              'How do you ensure smooth team integration and onboarding?',
            faqPara: (
              <>
                Simform’s tried and tested engagement model of team extension
                has been refined over the last 10 years. We make sure we are not
                limited by the distance, time zone differences, and
                communication fluency that come with remote resources.
                <br />
                <br />
                Selecting The Success Team
                <br />
                <br />
                If the client’s team is not used to working with remote talent,
                it may take some time to work out processes and learn best
                practices. That's why we start team integration by assigning a
                Technical Lead (sometimes local to the client’s location), a
                project manager, and a customer experience manager.
                <br />
                <br />
                Tech lead shepherds our development team to execute quality
                software on time.
                <br />
                <br />
                A local project manager helps you transition into remote working
                environment smoothly.
                <br />
                <br />
                Customer experience managers run a continuous feedback cycle on
                both ends to look for whatever could go wrong. They keep an eye
                on 15 project success parameters that we have defined to make
                sure all things are running smoothly.
                <br />
                <br />
                Knowledge Transfer and Team Integration
                <br />
                <br />
                Our team works with you for a minimum of two weeks. Individual
                new hiree onboarding is replaced with full team onboarding from
                your side.
                <br />
                <br />
                Our Tech Lead and PM will work closely with the client for the
                first few weeks of cooperation to work out an efficient remote
                working model and help to set up all the agile processes
                necessary for a geographically distributed team.
                <br />
                <br />
                Many times our clients already have a tech team and all the
                processes are well defined. In that case, we do a two-week trial
                execution so that our team can adopt and work in a way that fits
                your team’s workflow.
                <br />
                <br />
                Our team spends the time to understand your processes, who the
                stakeholders are, who the users are by doing sessions around
                user stories, code architecture (system or functional level),
                and most importantly business goals. Technology alignment and
                collaboration with your developers is critical here.
                <br />
                <br />
                We try to learn as much as possible in these two weeks. How you
                like to work, how the code should be structured, how tasks
                should be assigned, how questions should be asked, how
                suggestions should be given, how to do standups etc.
              </>
            ),
          },
          {
            faqTitle:
              'What communication processes do you follow to ensure successful remote collaboration?',
            faqPara: (
              <>
                We make remote work
                <br />
                <br />
                We hear frequently from prospective clients that it takes
                forever to release new features, that users aren't adopting
                products, and that finished work hasn't met their expectations.
                <br />
                <br />
                The solution: Communication, lots of it. We build teams with a
                focus on collaboration, consistent communication, and
                transparency in working toward a shared goal: a great product.
                <br />
                <br />
                Constant communication ensures there are absolutely no surprise
                breaks in execution. We use video calls, daily stand-ups,
                one-on-one check-ins and (if possible) a once a year “retreat”
                where everyone can get some face time.
                <br />
                <br />
                Our fully integrated team is self-managed and operates
                autonomously
                <br />
                <br />
                Simform team integrates into your team, participating in standup
                and scrum meetings, weekly demos, weekly retrospectives.
                <br />
                <br />
                Daily stand-ups
                <br />
                We do daily stand-ups where everyone gets on a video chat and
                tells you what they are working on that day and the previous
                day. When you’ve got people working for you in multiple time
                zones, this can be challenging. But it is crucial to the success
                of your team.
                <br />
                <br />
                The Agile methodology calls for each contributor to go around,
                talk about what they’ve been working on, what they will be
                working on, estimate how long it’s going to take them, whether
                there are any critical blockers that could cause delay, and what
                their bandwidth looks like. These stand-ups are rigorously
                tracked by the Tech Lead.
                <br />
                <br />
                But we do not just rely on unstructured communication. Everyone
                at Simform uses PPP (progress, plans, problems) methodology
                which is used at companies like Facebook, Google, eBay, Skype
                etc. PPP is used to detail progress, issues, thoughts and
                anything else pertaining to their tasks.
                <br />
                <br />
                Weekly demonstrations
                <br />
                This one is simple: get everybody on a video chat, share
                screens, have people show their work, and then talk about it.
                <br />
                <br />
                If something is exceptional, call it out and appreciate the
                work. So the team stays motivated.
                <br />
                <br />
                If something is buggy or substandard, ask why and figure out the
                solution. This meeting is usually way longer than a stand-up as
                it is designed to literally “demo” the work that has been
                promised during stand-ups.
                <br />
                <br />
                Keep in mind that if there are any “semi-off-topic” follow-up
                questions then they should be saved for the later Retrospective
                meeting.
                <br />
                <br />
                Weekly Retrospectives
                <br />
                A weekly retrospective is where you all collectively review what
                went well and what could have been improved based on the demo.
                <br />
                <br />
                We use the lean method of holding a meeting. It is great because
                it gives everyone a voice—there’s an element of democracy in the
                development process now. You’re still “the boss” but everybody
                now has some skin in the game. This will also help to
                organically create a culture within your remote team—something
                we will cover at length in a future post.
                <br />
                <br />
                There’s really no “one-way” to optimize and manage a remote
                team. Everybody has got their own style and strengths. And your
                team and processes will evolve as they need to in order to meet
                the needs of your product and to optimize the time and output of
                your team.
              </>
            ),
          },
          {
            faqTitle: 'Who will own the intellectual property?',
            faqPara:
              'As a client, you will own all the intellectual property of all paid-for work. We provide an NDA to protect confidentiality. All our team members sign a strict contract to protect your IP.',
          },
        ],
        dividerImg: images.pdivider1,
      },
      {
        title: 'Deliver',
        altText: 'Deliver',
        heading: (
          <>
            Get{' '}
            <span className="highlight-head">quality software delivered</span>,
            on time, on budget
          </>
        ),
        mainpara: (
          <>
            <a href="/services/custom-software-development/">
              With our fully-managed software development service
            </a>
            , you get proven processes and expertise that ensures remote
            engagement delivers successful results. We take ownership and
            guarantee the delivery and quality of your project.
          </>
        ),
        faqProps: [
          {
            faqTitle: 'What does your development process look like?',
            faqPara: (
              <>
                1) Test Cases Driven User Stories for Clear Requirements -
                <br />
                When our engagement with the client starts we help them create
                detailed user stories and scope out the next few sprints.
                <br />
                <br />
                2) Intense Sprint Planning
                <br />
                In order to execute projects on time, you need to plan sprints
                as much as possible.
                <br />
                <br />
                3) Iterative Delivery
                <br />
                Typically, in an agile development process, we will divide the
                implementation process into several checkpoints rather than a
                single deadline. They are called iterations and are part of each
                sprint.
                <br />
                <br />
                4) Documentation
                <br />
                We document everything from application and code level
                architecture decisions, to live user stories document, to design
                focused UX/UI/Design System documents.
                <br />
                <br />
                5) Constant Communication and Retrospective
                <br />
                Stand-Up Each workday begins with a brief team stand-up meeting
                to discuss what we did yesterday, and what we plan to do today.
                <br />
                <br />
                6) Code review
                <br />
                A successful peer review strategy for code review requires a
                balance between strictly documented processes and a
                non-threatening, collaborative environment.
                <br />
                <br />
                7) Integrating QA in the development process
                <br />
                We have gotten much better about integrating QA in our
                development process. We used to loop QA in after our code was
                pushed to the master branch, which was clunky.
                <br />
                <br />
                Now our QA engineers will identify and create a test plan as
                soon as we begin working on the next sprint. As initial
                development nears completion, an engineer and a QA engineer will
                run through test scenarios together.
                <br />
                <br />
                8) Post-release
                <br />
                It’s important to circle back and review how the process went
                once you’re done, be it a success or failure.
                <br />
                <br />
                Did the testing rightly model the production scenario?
                <br />
                Did your team correctly estimate the effort required to release
                a product?
                <br />
                How is the product running in production?
                <br />
                <br />
                Review how well the team performed by revisiting the
                implementation and testing checkpoints. Your team will adjust
                their performance accordingly as they grow used to being held
                accountable for every step in this process.
              </>
            ),
          },
          {
            faqTitle: 'What is KPI driven development?',
            faqPara: (
              <>
                After all, we are a software company and we use technology to
                make collaboration work. The whole company is run through
                several tools, many of them proprietary, to help make fully
                integrated and self-managed teams operate autonomously.
                <br />
                <br />
                All top athletes have great coaches and use comprehensive data
                and analytics to help tune their performance. We believe all top
                performers can use the same data and analytics to help them get
                even better.
                <br />
                <br />
                As a result, we've developed a data-driven productivity app—for
                how our team works. It effortlessly collects more than 20 data
                points and provides analytics that enables individuals and teams
                to use real data on how they work to bring their performance to
                another level.
                <br />
                <br />
                In alternative weekly retrospectives meetings, we discuss the
                developer performance dashboard. This has all the information
                about the overall status of your project, key milestone dates,
                sprint plans, productivity of developers, velocity of
                developers, story points worked on developers, complex tasks
                worked by developers etc.
              </>
            ),
          },
          {
            faqTitle:
              'How does risk free trial period work? What is Simform Guarantee?',
            faqPara: (
              <>
                We know that if client’s project launches smoothly, they’ll come
                back for more. We're willing to over-invest in guaranteeing
                results, rather than under-invest to make our financial reports
                look pretty in the short-run.
                <br />
                <br />
                We offer a risk-free trial period of up to two weeks. You will
                only have to pay if you are happy with the development team and
                wish to continue.
                <br />
                <br />
                This is a good way to ensure that the freelance developer is a
                good fit. If you don’t find them to be extraordinary, you will
                not be charged and to make sure your time isn't wasted we will
                assign someone to guarantee the work is done for free.
                <br />
                <br />
                Simform maintains a variety of insurance to protect and satisfy
                company-level obligations for our enterprise partners.
                <br />
                <br />
                With other platforms client’s IP and their security isn't as
                safe. And reliability, responsibility, and long term commitment
                of freelancers are questionable.
              </>
            ),
          },
        ],
      },
    ],
  },
  getServiceProps: {
    heading: <>What you get</>,
    getserviceLists: [
      {
        icon: images.topTalent,
        title: 'Top Talent',
        content: (
          <>
            <strong>Thoroughly vetted team</strong> of talent that is
            incentivized to deliver.
          </>
        ),
        className: 'sage-green',
      },
      {
        icon: images.zeroRampUp,
        title: 'Zero Ramp-Up',
        content: (
          <>
            <strong>Kick off new projects</strong> fast. We do all the work of
            assembling the right experts to scope and start new initiatives.
          </>
        ),
        className: 'sky-blue',
      },
      {
        icon: images.managedProcess,
        title: 'Managed Process',
        content: (
          <>
            Your <strong>dedicated Tech Lead</strong> does all the heavy-lifting
            of managing the project team so you can focus on what’s important.
          </>
        ),
        className: 'bluegrey',
      },
      {
        icon: images.reporting,
        title: 'Reporting',
        content: (
          <>
            <strong>Monitor the progress</strong> and health of your project
            based on key attributes like milestone tasks, bugs, blockers, and
            team happiness.
          </>
        ),
        className: 'pale-orange',
      },
      {
        icon: images.directAccess,
        title: 'Direct Access to Experts',
        content: (
          <>
            <strong>Get expert advice</strong> right when you need it from any
            agency in our network, whether it’s a data scientist, architect, UX
            designer, or niche technology expert.
          </>
        ),
        className: 'carnation',
      },
      {
        icon: images.projectSpecs,
        title: 'Project Specs and Scoping',
        content: (
          <>
            <strong>Our business analysis</strong> team will do all the work of
            gathering crucial project information from key stakeholders to
            deliver technical requirements that developers understand.
          </>
        ),
        className: 'perrywinkle',
      },
      {
        icon: images.ipSecurity,
        title: 'IP Security, Ownership and Licensing',
        content: (
          <>
            You <strong>get complete ownership</strong> and licensing for the
            software built by Simform, so you’re free to use and commercialize
            it.
          </>
        ),
        className: 'robin-s-egg',
      },
      {
        icon: images.codeDocumentation,
        title: 'Code Documentation and Transition',
        content: (
          <>
            We’ll <strong>provide everything</strong> you need to seamlessly
            hand off new software and train your internal teams.
          </>
        ),
        className: 'sage-green',
      },
      {
        icon: images.testing,
        title: 'Testing, Support, and Maintenance',
        content: (
          <>
            <strong>End-to-end testing,</strong> round-the-clock support for
            bugs, and on-demand maintenance.
          </>
        ),
        className: 'sky-blue',
      },
      {
        icon: images.insuarance,
        title: 'Insurance',
        content: (
          <>
            <strong>Simform maintains</strong> a variety of insurance to protect
            and satisfy company-level obligations for our enterprise partners.
          </>
        ),
        className: 'bluegrey',
      },
      {
        icon: images.easyAdmin,
        title: 'Easy administration',
        content: (
          <>
            <strong>We cover all contract</strong> work, payroll operations,
            team setup, employees’ retention, travel management, and logistics.
          </>
        ),
        className: 'pale-orange',
      },
      {
        icon: images.infrastructure,
        title: 'Excellent infrastructure',
        content: (
          <>
            We <strong>provide modern office</strong> buildings with the
            creative and inspiring working environment, equipped conference
            rooms, established IT and facility management.
          </>
        ),
        className: 'carnation',
      },
      {
        icon: images.talent,
        title: 'Talent management',
        content: (
          <>
            We help you <strong>transfer product knowledge</strong> to align
            remote software developers with your in-house team, keep people
            motivated and loyal, ensure personal development and growth.
          </>
        ),
        className: 'perrywinkle',
      },
      {
        icon: images.delivery,
        title: 'Delivery management',
        content: (
          <>
            We <strong>measure dedicated team </strong> productivity metrics and
            KPIs, monitor the quality of software, manage deliverables, and
            support startups with an individual program.
          </>
        ),
        className: 'robin-s-egg',
      },
      {
        icon: images.consulting,
        title: 'Consulting services',
        content: (
          <>
            We <strong>advise on aspects</strong> you need to strengthen, apply
            agile transformation practices, provide business analysis services,
            assist with software architecture, and help manage your software
            lifecycle
          </>
        ),
        className: 'sage-green',
      },
    ],
    CTACard: {
      title:
        'Speak to our experts to unlock the value Mobility, IoT, and Data Insights!',
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
