import React, { memo } from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/Seo'
import { HowItWorksProps } from '../components/Props/how-it-works'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import { sm } from 'config/variables'
import HowItWorksFlow from 'components/HowItWorksFlow/HowItWorksFlow'
import GetService from 'components/GetService/GetService'

export const HowItWorksPage = styled.div`
  .banner {
    margin-bottom: 50px;
    ${sm(`
      margin-bottom: 40px;
    `)}
    .banner-content {
      max-width: 763px;
    }
    .banner-img {
      display: none;
    }
  }
`

const DDT = memo(props => {
  const { location } = props
  return (
    <Layout mainClass="how-it-works-page" location={location}>
      <SEO
        title="How It Works"
        description="Build your development teams with Simform. Get guidance straight from our experts on how to create teams with a perfect set of technologies and right skills!"
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/how-it-works/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/how-it-works/',
          },
          {
            property: 'og:image',
            content: 'https://www.simform.com/banner.png',
          },
        ]}
      />
      <HowItWorksPage>
        <Banner
          bannerData={HowItWorksProps}
          showBannerImage={false}
          {...props}
        />
        <HowItWorksFlow howitworkflowData={HowItWorksProps} {...props} />
        <GetService getServiceData={HowItWorksProps} />
      </HowItWorksPage>
    </Layout>
  )
})

export default DDT

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/about-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 756) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    workflow1: file(relativePath: { regex: "/taking-selfie-img@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 440) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    workflow2: file(
      relativePath: { regex: "/great-engineering-illustration@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 405) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    workflow3: file(
      relativePath: { regex: "/fanatical-support-illustration@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 457) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    workflow4: file(relativePath: { regex: "/bespoke-training@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 440) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
