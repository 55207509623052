import styled from 'styled-components'
import { sh, md, sm, lg, themeColor, primaryColor } from 'config/variables'
import { images } from 'config/images'

export const HowItWorksFlowStyle = styled.section`
    padding-bottom: 90px;
    background: linear-gradient(to top, #eef2ff, #ffffff);
    margin: 0;
    position: relative;
    ${sm(`
        padding-bottom: 60px;
    `)}    
  .howitworkflow-wrapper{
    padding: 0 20px;
    position: relative;
    ${sm(`
        padding: 0;
    `)}
    .flow-content{
        margin-top: 86px;
        ${md(`
            margin-top: 50px;
        `)}
        .content-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 190px;
            position: relative;
            min-height: 550px;
            ${lg(`
                margin-bottom: 100px;
            `)}
            ${md(`
                flex-wrap: wrap;
                margin-bottom: 80px;
                justify-content: center;
            `)}
            ${sm(`
                margin-bottom: 50px;
            `)}
            .divider{
                position: absolute;
                bottom: -280px;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                ${lg(`
                    max-width: 500px;
                    bottom: -180px;
                `)}
                ${md(`
                    display: none;
                `)}
                img{
                    margin: 0 auto;
                    display: block;
                }
            }
            .content-blk{
                max-width: 600px;
                width: 100%;
                ${lg(`
                    max-width: calc(100% - 330px);
                `)}
                ${md(`
                    max-width: 100%;
                    margin-top: 40px;
                `)}
                h3{
                    font-size: 32px;
                    line-height: 38px;
                    margin-bottom: 11px;
                    ${sm(`
                        font-size: 24px;
                        line-height: 34px;
                    `)}
                }
                p{
                    font-size: 18px;
                    line-height: 26px;
                    a{
                        color: ${themeColor};
                        transition: all 0.3s ease;
                        &:hover{
                            color: ${primaryColor}
                        }
                    }                
                }
                .faq-accoridon-wrapper {
                    width: 100%;
                    margin-top: 26px;
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                    position: relative;
                    z-index: 1;
                    border-bottom: 1px solid #d1edeb;
                    .accordion-item {
                        padding: 21px 26px 15px 22px;
                        border: 1px solid #d1edeb;
                        background-color: #fff;
                        transition: all 0.3s ease;
                        overflow: auto;
                        -webkit-overflow-scrolling: touch;
                        border-bottom: 0;
                        ${md(`
                            padding: 11px 20px 15px 16px;
                        `)}                        
                        &.active {
                            background-color: rgb(249, 255, 255);
                            .panel{
                                max-height: 150px;
                                overflow-y: auto;
                            }
                            .title{
                                &:after {
                                    transform: rotateX(180deg);
                                }
                            }
                        }
                    .title {
                        color: #222;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 600;
                        letter-spacing: -0.22px;
                        position: relative;
                        padding-right: 40px;
                        display: block;
                        transition: all 0.3s ease;
                        cursor: pointer;
                        border: 0;
                        background: transparent;
                        box-shadow: initial;
                        text-align: left;
                        width: 100%;
                        margin-bottom: 10px;
                        ${sh(`
                            font-size: 18px;
                            line-height: 30px;
                        `)}
                        ${lg(`
                            font-size: 18px;
                            line-height: 26px;
                        `)}
                        ${sm(`
                            font-size: 16px;
                            line-height: 24px;
                            padding-right: 20px;
                        `)}
                        &:after {
                        content: '';
                        position: absolute;
                        width: 18px;
                        height: 12px;
                        background: url(${images.faqDownArrow});
                        top: 50%;
                        margin-top: -6px;
                        right: 0;
                        transition: all 0.3s ease;
                        }
                    }
                    .panel {
                        padding-right: 34px;
                        max-height: 0;
                        transition: max-height .15s ease-out;
                        overflow: hidden;                        
                        ${sm(`
                            padding-right: 0;
                        `)}
                        p {
                            margin-bottom: 0px;
                            color: #222;
                            font-size: 16px;
                            line-height: 26px;
                            ${sh(`
                                font-size: 16px;
                                line-height: 25px;
                            `)}
                            ${lg(`
                                font-size: 15px;
                                line-height: 25px;
                            `)}
                        }
                    }
                    }
                }
            }
            .img-blk{
                max-width: 450px;
                width: 100%;
                ${lg(`
                    max-width: 310px;
                `)}
                .gatsby-image-wrapper{
                    margin: 0 !important;
                }
            }
            &:nth-child(2n){
                flex-direction: row-reverse;
                .img-blk{
                    .gatsby-image-wrapper{
                        margin-right: 0 !important;
                        margin-left: auto !important;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
  }
`
